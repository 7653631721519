<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/tgAlertList' }">告警設定</el-breadcrumb-item>
                <el-breadcrumb-item>新增告警</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="form-box">
                <FormTemplate :formParams="form"
                              :deafulcomForm="deafuleditForm"
                              @reset-form="goback"
                              :btnText="'返回'"
                              @on-submit="onSubmit">
                    <template #group_id="group_id">
                        <el-space wrap
                                  size="large">
                            {{subStringFirst(group_id.comForm.group_id)}}<el-button size="mini"
                                       style="padding"
                                       :loading="searchLoading"
                                       @click="onSearch(group_id.comForm.tg_token)">查詢</el-button>
                        </el-space>
                    </template>
                    <template #expire_remind[0]="expire_remind">
                        <div class="expire-daily-remind">一 第<el-input @keyup="numberOnly"
                                      @blur="expire_remind.comForm.expire_remind[0] = $event.target.value"
                                      v-model="expire_remind.comForm.expire_remind[0]"></el-input>天</div>
                    </template>
                    <template #expire_remind[1]="expire_remind">
                        <div class="expire-daily-remind">二 第<el-input @keyup="numberOnly"
                                      @blur="expire_remind.comForm.expire_remind[1] = $event.target.value"
                                      v-model="expire_remind.comForm.expire_remind[1]"></el-input>天</div>
                    </template>
                    <template #expire_remind[2]="expire_remind">
                        <div class="expire-daily-remind">三 第<el-input @keyup="numberOnly"
                                      @blur="expire_remind.comForm.expire_remind[2] = $event.target.value"
                                      v-model="expire_remind.comForm.expire_remind[2]"></el-input>天</div>
                    </template>
                    <template #expire_remind[3]="expire_remind">
                        <div class="expire-daily-remind">四 第<el-input @keyup="numberOnly"
                                      @blur="expire_remind.comForm.expire_remind[3] = $event.target.value"
                                      v-model="expire_remind.comForm.expire_remind[3]"></el-input>天</div>
                    </template>
                    <template #expire_daily_remind="expire_daily_remind">
                        <div class="expire-daily-remind">到期<el-input @keyup="numberOnly"
                                      @blur="expire_daily_remind.comForm.expire_daily_remind = $event.target.value"
                                      v-model="expire_daily_remind.comForm.expire_daily_remind"></el-input>天內</div>
                    </template>

                </FormTemplate>
            </div>
            <!-- 编辑弹出框 -->
            <el-dialog title="機器人群組名單"
                       v-model="editVisible"
                       width="480px"
                       center>
                <div class="dialog-contect">以下為目前有加入告警機器人的群組名單，請選擇要通知的群組(一組)</div>
                <el-radio-group v-model="radio"
                                v-for="(group,index) in groupOptions"
                                :key="index">
                    <el-radio :label="group.group_id">{{subStringFirst(group.group_id)}} {{group.title}}</el-radio>
                </el-radio-group>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary"
                                   @click="saveEdit">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { alertAddRobot, groupSearch } from '../../../api/index'
import FormTemplate from '../../../components/FormTemplate.vue'
export default {
    components: {
        FormTemplate,
    },
    data() {
        return {
            form: {
                labelWidth: '120px',
                formList: [
                    {
                        label: '機器人token',
                        key: 'tg_token',
                        placeholder: '請輸入機器人token',
                        type: 'INPUT',
                        span: 8,
                    },
                    {
                        label: '群組ID',
                        key: 'group_id',
                        type: 'SLOT',
                        span: 8,
                    },
                    {
                        label: '是否全站通知',
                        key: 'is_global_alert',
                        type: 'SELECT',
                        lists: [
                            {
                                value: 'Y',
                                label: '是',
                            },
                            {
                                value: 'N',
                                label: '否',
                            },
                        ],
                        span: 4,
                    },
                    {
                        label: '機器人名稱',
                        key: 'robot_name',
                        placeholder: '請輸入機器人名稱',
                        type: 'INPUT',
                        require: true,
                        span: 8,
                    },
                    {
                        label: '商戶ID',
                        key: 'merchant',
                        placeholder: '請輸入商戶ID ex:1,2,3',
                        type: 'INPUT',
                        span: 8,
                    },
                    {
                        label: '自動續約',
                        key: 'renew_auto_notice',
                        type: 'SELECT',
                        lists: [
                            {
                                value: 'Y',
                                label: '開啟',
                            },
                            {
                                value: 'N',
                                label: '關閉',
                            },
                        ],
                        span: 4,
                    },
                    {
                        label: '過期通知',
                        key: 'expire_notice',
                        type: 'SELECT',
                        lists: [
                            {
                                value: 'Y',
                                label: '開啟',
                            },
                            {
                                value: 'N',
                                label: '關閉',
                            },
                        ],
                        span: 4,
                    },
                    {
                        label: '到期提醒',
                        key: 'expire_remind[0]',
                        type: 'SLOT',
                        specialRequire: { type: 'blur', label: '上限為3位正整數，第一欄為必填且不得為0' },
                        require: true,
                        span: 8,
                    },
                    {
                        label: '',
                        key: 'expire_remind[1]',
                        type: 'SLOT',
                        span: 8,
                    },
                    {
                        label: '',
                        key: 'expire_remind[2]',
                        type: 'SLOT',
                        span: 8,
                    },
                    {
                        label: '',
                        key: 'expire_remind[3]',
                        type: 'SLOT',
                        span: 8,
                    },
                    {
                        label: '到期提醒(每日',
                        key: 'expire_daily_remind',
                        type: 'SLOT',
                        specialRequire: { type: 'blur', label: '必須輸入,需填寫三位數以內大於0的正整數,該天數需小於上方到期提醒的設定天數' },
                        require: true,
                        span: 8,
                    },
                ],
            },
            deafuleditForm: {
                group_id: '',
                tg_token: '',
                merchant: '',
                expire_remind: [],
                renew_auto_notice: 'N',
                expire_notice: 'N',
                is_global_alert: 'N',
            },
            editVisible: false,
            groupOptions: [],
            radio: '',
            domainName: '',
            tableData: [],
            searchLoading: false,
        }
    },
    mounted() {},
    methods: {
        getGroup(token) {
            groupSearch({ token: token }).then((res) => {
                this.groupOptions = res.data.data_list
                this.editVisible = true
                this.searchLoading = false
            })
        },
        onSearch(token) {
            if (!token) {
                this.$message.error('請輸入機器人token')
                return
            }
            this.searchLoading = true
            this.getGroup(token)
        },
        onSubmit(form) {
            form.expire_remind = form.expire_remind.filter((el) => el && el != 0) //判斷0跟空直拿掉
            alertAddRobot(form).then((res) => {
                if (res.code == 200) {
                    this.$message.success(res.message)
                    this.$router.push({ path: 'tgAlertList' })
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        saveEdit() {
            this.deafuleditForm.group_id = this.radio
            this.editVisible = false
        },
        goback() {
            this.$router.push({ path: 'tgAlertList' })
        },
        subStringFirst(str) {
            if (str) {
                return parseInt(str.toString().substr(1))
            }
        },
        numberOnly(event) {
            if (event.target.value.length == 1) {
                event.target.value = event.target.value.replace(/[^1-9]/g, '')
            } else {
                event.target.value = event.target.value.replace(/\D/g, '')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.expire-daily-remind {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
        width: 65px;
        padding: 0 10px;
    }
}
.submit-btn {
    text-align: center;
}
.dialog-contect {
    padding-bottom: 10px;
}
.el-radio-group {
    display: grid;
}
.el-radio {
    margin-bottom: 5px;
}
.red {
    color: #ff0000;
}
</style>
<style lang="scss">
@media screen and (max-width: 800px) {
    .el-dialog {
        width: 80% !important;
    }
}
</style>